.mapboxgl-popup-close-button {
	display: none;
}

.mapboxgl-popup-content {
	background: #333 !important;
	border-radius: 4px !important;
	padding: 16px !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
	border-bottom-color: #333 !important;
}
