.mask {
	display: none;
}
.reactour__helper {
	background-color: blue;
	color: #2d2323;
}

.helper {
	line-height: 1.3;
	background-color: green;
	color: #2d2323;
}
